import React from 'react'
import styled from 'styled-components'
import DeviceDetector from 'src/components/DeviceDetector'
import withLocation from 'src/hoc/withLocation'
import PATHS from 'src/utils/paths'
import {
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  PHONE_NUMBER,
  WEBSITE_ADDRESS,
  CONTACT_EMAIL,
  APP_NAME
} from 'src/utils/app'

import background from 'src/assets/images/landing/footer.svg'
import backgroundMobile from 'src/assets/images/landing/footer-mobile.png'
import Container from 'src/components/Container'
import Grid from 'src/components/atoms/Grid'

import Panda from 'src/assets/images/landing/Panda3.svg'
import BrandLogo from 'src/assets/images/landing/logo-desmart-white.svg'
import MailIcon from 'src/components/icons/Mail'
import GlobeIcon from 'src/components/icons/Globe'
import PinIcon from 'src/components/icons/Pin'
import PhoneIcon from 'src/components/icons/Phone'

export const Footer = props => {
  const {
    location: { pathname }
  } = props
  const primaryState = pathname === PATHS.homepage

  return (
    <DeviceDetector>
      <FooterWrapper primaryState={primaryState}>
        <BackgroundImagePlaceholder
          className='web'
          src={background}
          alt='welcome-section-background-image'
        />
        <BackgroundImagePlaceholder
          className='mobile'
          src={backgroundMobile}
          alt='welcome-section-background-image'
        />
        <BackgroundImage
          className='web'
          src={background}
          alt='welcome-section-background-image'
        />
        <BackgroundImage
          className='mobile'
          src={backgroundMobile}
          alt='welcome-section-background-image'
        />
        <FooterContent>
          <Container>
            <div className='footer__logo'>
              <img
                src={Panda}
                className='footer__logo-image'
                alt='panda-heart'
              />
              <img
                src={BrandLogo}
                className='web footer__logo-desmart'
                alt='desmart-logo'
              />
              <div className='mobile footer__logo-app-name'>{APP_NAME}</div>
            </div>
            <Grid container>
              <Grid item sm={6} xs={12} md={6} lg={3}>
                <div className='footer__contact-item'>
                  <div className='footer__contact-item-icon'>
                    <PinIcon />
                  </div>
                  <div>
                    <div>{ADDRESS_LINE_1}</div>
                    <div>{ADDRESS_LINE_2}</div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={6} xs={12} md={6} lg={3}>
                <div className='footer__contact-item'>
                  <div className='footer__contact-item-icon'>
                    <PhoneIcon />
                  </div>
                  <div>{PHONE_NUMBER}</div>
                </div>
              </Grid>
              <Grid item sm={6} xs={12} md={6} lg={3}>
                <div className='footer__contact-item'>
                  <div className='footer__contact-item-icon'>
                    <GlobeIcon />
                  </div>
                  <div>
                    <a
                      href={`https://${WEBSITE_ADDRESS}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='footer__contact-item-link'
                    >
                      {WEBSITE_ADDRESS}
                    </a>
                  </div>
                </div>
              </Grid>
              <Grid item sm={6} xs={12} md={6} lg={3}>
                <div className='footer__contact-item'>
                  <div className='footer__contact-item-icon'>
                    <MailIcon />
                  </div>
                  <div>{CONTACT_EMAIL}</div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </FooterContent>
      </FooterWrapper>
    </DeviceDetector>
  )
}

export default withLocation(Footer)

const FooterWrapper = styled.div`
  position: relative;
  background-color ${props =>
    props.primaryState
      ? props.theme.palette.common.white
      : props.theme.palette.common.catskillWhite};
`

const BackgroundImagePlaceholder = styled.img`
  visibility: hidden;
  width: 100%;
`

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const FooterContent = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: ${props => props.theme.palette.common.white};
  margin-bottom: 65px;
  font-size: 16px;

  ${props => props.theme.breakpointsMedia.mobile} {
    margin-bottom: 42px;
  }

  ${props => props.theme.breakpointsMedia.smallDesktop} {
    margin-bottom: 42px;
  }

  .footer__contact-item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-right: 10px;

    ${props => props.theme.breakpointsMedia.mobile} {
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .footer__contact-item-icon {
    margin-right: 10px;

    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 26px;
      margin-right: 26px;
    }
  }

  .footer__logo {
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.theme.breakpointsMedia.smallDesktop} {
      margin-bottom: 15px;
    }
  }

  .footer__contact-item-link {
    color: ${props => props.theme.palette.common.white};
    text-decoration: none;
  }

  .footer__logo-image {
    width: 124.5px;

    ${props => props.theme.breakpointsMedia.mobile} {
      width: 72px;
    }

    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 72px;
    }
  }

  .footer__logo-desmart {
    width: 112.5px;
    margin-left: 20px;
    margin-top: 40px;
  }

  .footer__logo-app-name {
    font-size: 23.25px;
  }
`
