import styled from 'styled-components'

export default styled.div`
  box-sizing: border-box;
  max-width: 1030px;
  width: 100%;
  margin: 0 auto;
  
  ${props => props.theme.breakpointsMedia.smallDesktop} {
     max-width: 768px;
  }
`
